import React from 'react'
import Link from 'next/link'
import dynamic from 'next/dynamic'
import defaultTheme from '@ntuctech/devex-tangram/Theme/defaultTheme'
import { SvgFavourite, SvgFavouriteFilled } from '@ntuctech/devex-tangram'
import GlobalContext from '../GlobalContext'
import { CheckoutAddressConsumer } from '../CheckoutAddressProvider/CheckoutAddressProvider'
import CartCounter, { OutOfStockInProductCards } from './../CartCounter'
import Price from './../Price'
import Text from './../Text'
import getPwpOffer, { filterSimplePromotion } from '../../lib/offer'
import { CartConsumer } from './../CartProvider'
import { WishlistConsumer } from './../WishlistProvider'
import throttle from 'lodash/throttle'
import ProgressiveImage from './../../components/ProgressiveImage'
import { EventTrackingConsumer } from '../EventTrackingProvider/EventTrackingProvider'
import MarketplaceItemLabelList from '../MarketplaceItemLabelList/MarketplaceItemLabelList'
const ShoppingListPopup = dynamic(() =>
  import('../ShoppingList/ShoppingListPopup/ShoppingListPopup')
)
import NineDayShelfLifeImg from '../../static/ShelfLife/9day.png'
import FourteenShelfLifeImg from '../../static/ShelfLife/14day.png'
import { withRouter } from 'next/router'
import { Router } from '../../routes'
import {
  isOutOfStock,
  OUT_OF_STOCK,
  IN_STOCK,
} from '../../lib/getInventoryStatus'
import {
  fetchCategory,
  getNCheckFn as getNCheck,
  GTM_ENV_VAR as GEV,
  PAGE_TYPES,
} from '../../lib/eventTrackerFunction'
import {
  getProductImpressionsTrackingData,
  getProductClickTrackingData,
  getSplitProductTrackingData,
} from '../../lib/getProductImpressionsTrackingData'
import {
  isCitrusProductTrackingEnabled,
  trackCitrusAdProductClick,
} from '../../lib/trackCitrus'
import {
  getHandlingTimeDisplayText,
  isBoughtBefore,
  isJwcTag,
} from '../../utils/Utils'
import JwcPlpNoBorderIcon from '../../static/jwc/jwclogonostroke.svg'
import TruncatedText from '../TruncatedText'
import RatingStars from '../RatingStars/RatingStars'
import { getRatingsConfig } from '../RatingStars/ratingsUtils'
import { getExpConfig, getConfigFor } from '../../utils/configService'
import { SPLIT_FEATURES } from '../../utils/constants'
import compact from 'lodash/compact'
import LocalStorageService from '../../utils/LocalStorageService'
import { IdleQueue } from '../../lib/idlize/IdleQueue'
import {
  trackingAddToCartForDY,
  trackingProductClickDY,
} from '../../utils/DyUtils'
const queue = new IdleQueue()

import {
  RecommendedProductWrapper,
  StyledContainer,
  StyledInfo,
  StyledSaveButton,
  StyledA,
  StyledNoImageIcon,
  PwpFlag,
  Div,
  PlainDiv,
  ImageContainer,
  PriceAndSaveButton,
  StyledTitle,
  StyledLabel,
  StyledTagWrapper,
  StyledJwcPlpIcon,
  PaddingBox,
  LPIcon,
  ShelfLifeImage,
  MarketplaceItemLabelListContainer,
  PromoLabelWraper,
  StyledDot,
  StyledPastPurchase,
  MetaDataContainer,
  ItemNameAndAddToCart,
} from './styles'
import {
  AMP_EVENT_NAME as AEN,
  formatCtaLocation,
  getPageTypeByUrl,
} from '../../lib/amplitude'
import {
  getCtaLocationMeta,
  getPreviousCtaLocationMeta,
  setCtaLocation,
  setCtaSublocation,
  setPreviousPageMeta,
} from '../../lib/utils'
import { getAddAndRemoveFromCartEcommerceEvent } from '../ProductCard/utils'
import partition from 'lodash/partition'
import ProductCampaignLabel, {
  CampaignLabelContainer,
} from '../ProductCampaignLabel/ProductCampaignLabel'
import ProductCardMetaData from '../ProductCardMetaData/ProductCardMetaData'
import { getAddRemoveCartMetaFromDelta } from '../../utils/tracking/trackingUtils'

class RecommendedProduct extends React.Component {
  constructor(props) {
    super(props)
    const digitalClubConfigData = getExpConfig(SPLIT_FEATURES.DC_CONFIG) || {}
    const digitalClubConfig = JSON.parse(digitalClubConfigData?.config || '{}')
    this.state = {
      showList: false,
      isJwcTagApplicable: false,
      notDefaultClick: false,
      ratingsConfig: getRatingsConfig() || {},
      digitalClubConfig,
    }
    this.productRef = null
    this.setProductRef = element => {
      this.productRef = element
    }
    this.handleShowList = this.handleShowList.bind(this)
    this.productDetailsCardEvent = this.productDetailsCardEvent.bind(this)
    this.productCartAction = this.productCartAction.bind(this)
    this.handleTrackOnClick = throttle(
      this.handleTrackOnClick.bind(this),
      2000,
      { trailing: false }
    )
    this.createObserver = this.createObserver.bind(this)
    this.processPromoDescription = this.processPromoDescription.bind(this)
    this.getPromoContent = this.getPromoContent.bind(this)
  }

  componentWillUnmount() {
    this.productRef = null
  }

  handleShowList(event, value) {
    if (event) {
      event.stopPropagation()
      event.preventDefault()

      const { isLoggedIn, details } = this.props

      if (!isLoggedIn) {
        const redirectPath =
          Router && Router.router ? Router.router.asPath : '/'
        window.location.href = `/login?redirect=${encodeURIComponent(
          redirectPath
        )}`
        return
      }

      const isAddToWishlist = !this.props.wishlistConsumer.includes(details)
      // Event tracking for mark item as favourite or save to wishlist.
      this.productDetailsCardEvent({
        eventAction: 'Shortlist',
        eventCat: 'Product-Core',
        value,
        amplitudeEventName: isAddToWishlist
          ? AEN.PRODUCT_ADDED_TO_LIST
          : AEN.PRODUCT_REMOVED_FROM_LIST,
        delta: isAddToWishlist ? 1 : -1,
      })

      this.setState({
        clientX: event.clientX,
        clientY: event.clientY,
      })
    }
    this.setState(prevState => {
      return {
        showList: !prevState.showList,
      }
    })
  }

  productDetailsCardEvent({
    eventAction,
    eventCat,
    value,
    amplitudeEventName,
    delta,
  }) {
    const {
      details,
      loc,
      ctaSublocation,
      track,
      pageType,
      unlimitedStock,
      bulkOrderThreshold,
      storeBulkOrderThreshold,
      checkoutAddress,
      countOf,
      remoteConfig,
      productPosition,
      metaData,
      isVoucherDetails,
      ga4ProductListName,
    } = this.props
    const { ratingsConfig } = this.state
    const productInfo = details
    const statistics = details?.reviews?.statistics || {}
    const storeSpecific = details.storeSpecificData[0]
    const categoryList = fetchCategory(productInfo.primaryCategory)
    const offerPrice = (storeSpecific.mrp - storeSpecific.discount).toFixed(2)
    const offersToTrack = details.offers && details.offers.slice(0, 2)
    const promoText =
      offersToTrack && offersToTrack.map(offer => offer.description).join('|')
    const itemIsOutOfStock = isOutOfStock({
      value: countOf(details),
      stock: storeSpecific.stock,
      unlimitedStock,
      bulkOrderThreshold,
      storeBulkOrderThreshold,
      checkoutAddress,
    })

    let pageTypeToTrack = ''
    if (loc === 'ProductWidget-SimilarProducts') {
      pageTypeToTrack = PAGE_TYPES.PRODUCT_DETAIL
    } else {
      pageTypeToTrack = PAGE_TYPES.LISTING_DETAIL
    }

    const isProductBoughtBefore =
      productInfo?.pastPurchases && isBoughtBefore(productInfo?.pastPurchases)
        ? 'yes'
        : 'no'

    const event = {
      [GEV.KEYWORD_SELECTED]: this.props?.searchTerm || '',
      [GEV.ALGOLIA_EXP]:
        metaData?.abTestId && metaData?.abTestVariantId
          ? `algolia-${metaData?.abTestId}-${metaData?.abTestVariantId}`
          : undefined,
      [GEV.EVENT_CAT]: eventCat,
      [GEV.EVENT_ACTION]: eventAction,
      [GEV.PROD_ID]: getNCheck(productInfo, 'clientItemId', ''),
      [GEV.PRODUCT_ID_ZOP]: getNCheck(productInfo, 'id', ''),
      [GEV.PROD_NAME]: getNCheck(productInfo, 'name', ''),
      [GEV.PROD_CUR]: getNCheck(storeSpecific, 'currency.name', ''),
      [GEV.PROD_QUANT]: value ? value.toString() : '0',
      [GEV.PROD_BRAND]: getNCheck(productInfo, 'brand.name', ''),
      [GEV.RETAIL_PRICE]: parseFloat(getNCheck(storeSpecific, 'mrp', ''))
        .toFixed(2)
        .toString(),
      [GEV.OFFER_PRICE]: offerPrice ? offerPrice.toString() : '',
      [GEV.PROD_CAT]: 'Retail',
      [GEV.INVTRY_COUNT]: getNCheck(storeSpecific, 'stock', ''),
      [GEV.PAGE_TYPE]: pageType ? pageType : pageTypeToTrack,
      [GEV.PROMO_TEXT]: promoText,
      [GEV.INVENTORY_STATUS]: itemIsOutOfStock ? OUT_OF_STOCK : IN_STOCK,
      [GEV.AMPLITUDE_EVENT_NAME]: amplitudeEventName,
      [GEV.PRODUCT_RATING]: productInfo?.reviews?.statistics?.average,
      [GEV.PROMOTION_COUNT]: productInfo?.offers?.length || 0,
      [GEV.PROMOTION_TYPES]: compact(
        productInfo?.offers?.map(offer => offer?.metaData?.sapReference) || []
      ),
      [GEV.PROMOTION_DESCRIPTIONS]: compact(
        productInfo?.offers?.map(offer => offer.description) || []
      ),
      [GEV.AMPLITUDE_CTA_SUBLOCATION]: ctaSublocation,
      [GEV.AMPLITUDE_CTA_LOCATION]: {
        pageName: getPageTypeByUrl(),
        pageMeta: getCtaLocationMeta() || [],
      },
      [GEV.AMPLITUDE_2CLICK_CALL_TO_ACTION_LOCATION]: {
        pageName: getPageTypeByUrl(sessionStorage.getItem('previousRoute')),
        pageMeta: getPreviousCtaLocationMeta(),
      },
      [GEV.AMPLITUDE_PRODUCT_QUANTITY]: delta,
      [GEV.AMPLITUDE_IS_PAST_PURCHASE]: isProductBoughtBefore,
      ...getAddAndRemoveFromCartEcommerceEvent({
        productPosition,
        productInfo,
        eventAction,
        categoryList,
        storeSpecific,
        itemIsOutOfStock,
        remoteConfig,
        checkoutAddress,
        loc,
        statistics,
        details,
        ga4ProductListName,
        delta,
      }),
    }

    /* istanbul ignore if  */
    if (isVoucherDetails) {
      event[GEV.ASSET_TYPE] = 'REWARDS'
      event[GEV.AMPLITUDE_CTA_LOCATION] = {
        pageName: 'voucher details',
        pageMeta: getCtaLocationMeta() || [],
      }
      event[GEV.EVENT_CAT] = 'Voucher_Wallet'
      event[GEV.EVENT_ACTION] = 'Click'
      event[GEV.SCREEN_NAME] = 'Voucher Wallet'
      event[GEV.PAGE_TYPE] = 'Voucher Wallet'
      event[GEV.EVENT] = 'hm_push_event'
      event[GEV.EVENT_LABEL] = 'voucher-wallet_promocode_detail_add_to_cart'
    }

    /* istanbul ignore if  */
    if (productPosition) {
      event[[GEV.PRODUCT_POSITION]] = productPosition
    }
    /* istanbul ignore if  */
    if (['AddToCart', 'RemoveFromCart'].includes(eventAction)) {
      event[[GEV.DIMENSION97]] =
        isCitrusProductTrackingEnabled(remoteConfig) && productInfo?.adId
          ? `ct_${productInfo?.adId}`
          : ''
      event[[GEV.DIMENSION106]] = details?.track || ''

      event[GEV.ASSET_TYPE] = 'fpon'
      event[GEV.SNIPPET_VERSION] = 'ga4'
      event[GEV.SCREEN_NAME_GA4] = formatCtaLocation(
        event[GEV.AMPLITUDE_CTA_LOCATION],
        'grocery-online'
      )
      if (eventAction === 'AddToCart') {
        event[GEV.EVENT_CAT] = 'ecommerce-engagement'
        event[GEV.EVENT_ACTION] = event[GEV.EVENT_ACTION].toLowerCase()
        event[GEV.IS_LOWER_CASE_LOGGED_IN_STATUS] = true
      }
    }
    if (
      eventAction === 'AddToCart' &&
      ratingsConfig?.isFeatureEnabled &&
      ratingsConfig?.isPlpStarsEnabled
    ) {
      event[[GEV.DIMENSION101]] = statistics?.averageDisplay || ''
      event[[GEV.DIMENSION102]] = statistics?.total || ''
    }

    if (eventAction === 'AddToCart') {
      const dyPayload = trackingAddToCartForDY(
        details,
        this.props?.cartItems,
        delta,
        this.props?.metaData
      )
      track({ ...dyPayload })
    }

    if (loc) {
      event[[GEV.EVENT_LABEL]] = `Loc=${loc}`
    }
    if (metaData?.indexName) {
      event[GEV.AMPLITUDE_EVENT_TYPE] = 'conversion'
      event[GEV.AMPLITUDE_SEARCH_QUERY_ID] = metaData?.queryId
      event[GEV.AMPLITUDE_SEARCH_INDEX_NAME] = metaData?.indexName
      event[GEV.AMPLITUDE_EVENT_SCOPE] = 3
    }
    if (productInfo.metaData && productInfo.metaData['Storage Information']) {
      event[[[GEV.STORAGE]]] = productInfo.metaData['Storage Information']
    }
    const keys = Object.keys(categoryList)
    keys.forEach(item => {
      event[item] = categoryList[item]
    })

    track(event)
  }

  productCartAction(delta) {
    const { details, cartItems, remoteConfig } = this.props
    const adIds = cartItems?.[details.id]?.adIds || []
    if (delta >= 1) {
      /* istanbul ignore next */
      trackCitrusAdProductClick(adIds, remoteConfig)
    }

    this.productDetailsCardEvent({
      ...getAddRemoveCartMetaFromDelta(delta),
      value: delta,
      delta,
    })
  }

  handleTrackOnClick(e) {
    if (e.button === 0) {
      e.preventDefault()
    }
    const {
      details,
      track,
      metaData,
      trackSplit,
      ctaSublocation,
      ctaLocation,
      productPosition,
    } = this.props
    if (metaData) {
      LocalStorageService.setItem(
        'metaData',
        JSON.stringify({ ...metaData, productPosition })
      )
    }
    if (this.props.onProductImpressions) {
      this.props.onProductImpressions({
        forceTrack: true,
        eventType: 'abandon',
      })
    }
    const eventData = getProductClickTrackingData({ productObj: this.props })
    setCtaSublocation(ctaSublocation)
    setCtaLocation(ctaLocation)
    track(eventData)

    setPreviousPageMeta({
      productPosition,
    })

    // track for dy
    const dyPayload = trackingProductClickDY(details, metaData)
    track(dyPayload)

    //tracking ecomm product click for split
    const splitEventData = getSplitProductTrackingData({
      eventType: 'splitProductClick',
      productObj: this.props,
    })
    trackSplit([splitEventData])

    if (e.button === 0) {
      Router.push(
        details?.adId
          ? `/productDetails?slug=${details.slug}&adId=${details.adId}`
          : `/productDetails?slug=${details.slug}`,
        `/product/${details.slug}`
      )
    } else if ([1, 2].includes(e.button)) {
      this.setState({ notDefaultClick: true })
    }
  }

  createObserver(params = {}) {
    if (this.props.createObserver) {
      const productData = getProductImpressionsTrackingData({
        productObj: this.props,
        ga4ProductListName: this.props.ga4ProductListName,
      })
      const options = {
        productRef: this.productRef,
        productTrackObj: productData,
        splitTrackObj: getSplitProductTrackingData({
          eventType: 'splitProductImpression',
          productObj: this.props,
        }),
        callAction: 'onLoad',
      }
      if (params?.action === 'afterUpdate') {
        options.callAction = 'afterUpdate'
      }
      this.props.createObserver(options)
    }
  }

  componentDidMount() {
    queue.pushTask(() => {
      this.createObserver()
    })

    const isJwcTagApplicable = isJwcTag(this.props.details?.tags)
    this.setState({ isJwcTagApplicable })
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.details.id !== this.props.details.id ||
      (prevProps.details.id === this.props.details.id &&
        (prevProps.checkoutAddress.storeId !==
          this.props.checkoutAddress.storeId ||
          prevProps.productListName !== this.props.productListName))
    ) {
      const isJwcTagApplicable = isJwcTag(this.props.details?.tags)
      if (prevState.isJwcTagApplicable !== isJwcTagApplicable) {
        this.setState({ isJwcTagApplicable })
      }
      this.createObserver({ action: 'afterUpdate' })
    }
  }
  getPromoContent(offers, sp, otherOffers) {
    const { isJwcTagApplicable, digitalClubConfig } = this.state
    const counts = offers?.length || 0
    let info = null
    let isSimplePromo = false
    let dcItem = null
    const nonDCOtherOffers = []
    const { isEnabled: isPromoPriorityEnabled } = getConfigFor(
      SPLIT_FEATURES.PROMO_PRIORITY
    )
    const [ZIA1Offers, nonZIA1Offers] = partition(otherOffers, offer => {
      return offer.metaData?.sapReference?.includes?.('ZIA1')
    })
    const nonSimplePromoOffers = isPromoPriorityEnabled
      ? nonZIA1Offers
      : otherOffers
    nonSimplePromoOffers?.forEach(function (item) {
      if (
        Array.isArray(item?.userSet?.data) &&
        String(item?.userSet?.data[0]?.tagId) ===
          String(digitalClubConfig?.offerTagId)
      ) {
        dcItem = item
      } else {
        nonDCOtherOffers.push(item)
      }
    })
    if (nonDCOtherOffers?.length) {
      info = nonDCOtherOffers[0]
    } else {
      if (sp) {
        isSimplePromo = true
        info = sp
      } else {
        if (isPromoPriorityEnabled) {
          // show zlia offer if any
          if (ZIA1Offers?.length) {
            info = ZIA1Offers[0]
          }
        }
      }
    }
    if (info) {
      let preFormattedText = ''
      let formattedText = ''
      let postFormattedText = ''
      let descriptionText = ''
      const countToShow = isJwcTagApplicable ? counts : counts - 1
      if (isSimplePromo) {
        const storeData = this.props.details.storeSpecificData
        const discount = storeData ? storeData[0].discount : '0'
        descriptionText =
          discount !== '0'
            ? `Save $${
                storeData[0].discount % 1 === 0
                  ? Number(storeData[0].discount)
                  : Math.abs(storeData[0].discount).toFixed(2)
              }`
            : info.discount
      } else {
        const {
          preFormattedTextLabel,
          formattedTextLabel,
          postFormattedTextLabel,
          description,
        } = this.processPromoDescription(info)
        preFormattedText = preFormattedTextLabel
        formattedText = formattedTextLabel
        postFormattedText = postFormattedTextLabel
        descriptionText = description
      }
      return (
        <StyledTagWrapper>
          <PromoLabelWraper>
            <PwpFlag
              data-testid="promo-label"
              size="small"
              weight="bold"
              color="#ffffff"
              borderstyle="single"
            >
              {formattedText.length ? (
                <TruncatedText clamp={1} className="inline">
                  <span>{preFormattedText}</span>
                  <strong>{formattedText}</strong>
                  <span>{postFormattedText}</span>
                </TruncatedText>
              ) : (
                <TruncatedText clamp={1}> {descriptionText}</TruncatedText>
              )}
            </PwpFlag>

            {countToShow > 0 && (
              <PwpFlag
                data-testid="promo-label-counter"
                size="small"
                weight="bold"
                color={defaultTheme.ColorBaseBrandSecondary500}
                bgcolour={defaultTheme.ColorBaseBrandSecondary50}
                borderstyle="last"
              >
                {`+${countToShow}`}
              </PwpFlag>
            )}
          </PromoLabelWraper>
        </StyledTagWrapper>
      )
    } else {
      if (isJwcTagApplicable) {
        return (
          <StyledTagWrapper>
            <PromoLabelWraper>
              <StyledJwcPlpIcon
                data-testid="promo-label-with-jwc"
                alt="Just Wine Club Tag"
                bgcolour={defaultTheme.ColorBaseBrandSecondary50}
                src={JwcPlpNoBorderIcon}
                rightMargin
              />
              {counts > 0 && (
                <PwpFlag
                  data-testid="promo-label-counter"
                  size="small"
                  weight="bold"
                  color={defaultTheme.ColorBaseBrandSecondary500}
                  bgcolour={defaultTheme.ColorBaseBrandSecondary50}
                  borderstyle="last"
                >
                  {`+${counts}`}
                </PwpFlag>
              )}
            </PromoLabelWraper>
          </StyledTagWrapper>
        )
      } else {
        if (dcItem) {
          const {
            preFormattedTextLabel,
            formattedTextLabel,
            postFormattedTextLabel,
            description,
          } = this.processPromoDescription(dcItem)
          const countToShow = counts - 1
          return (
            <StyledTagWrapper>
              <PromoLabelWraper>
                <PwpFlag
                  data-testid="promo-label"
                  size="small"
                  weight="bold"
                  color={defaultTheme.ColorBaseBrandSecondary500}
                  bgcolour={defaultTheme.ColorBaseBrandSecondary50}
                  borderstyle="single"
                >
                  <LPIcon
                    alt="Digital Club"
                    width="0.875rem"
                    src={digitalClubConfig?.iconUrl}
                  />
                  {formattedTextLabel.length ? (
                    <TruncatedText clamp={1} className="inline">
                      <span>{preFormattedTextLabel}</span>
                      <strong>{formattedTextLabel}</strong>
                      <span>{postFormattedTextLabel}</span>
                    </TruncatedText>
                  ) : (
                    <TruncatedText clamp={1}> {description}</TruncatedText>
                  )}
                </PwpFlag>
                {countToShow > 0 && (
                  <PwpFlag
                    data-testid="promo-label-counter"
                    size="small"
                    weight="bold"
                    color={defaultTheme.ColorBaseBrandSecondary500}
                    bgcolour={defaultTheme.ColorBaseBrandSecondary50}
                    borderstyle="last"
                  >
                    {`+${countToShow}`}
                  </PwpFlag>
                )}
              </PromoLabelWraper>
            </StyledTagWrapper>
          )
        }
      }
    }
    return null
  }

  processPromoDescription(promo) {
    const { description, shortDescriptionA } = promo
    let promoDescription = description
    let preFormattedTextLabel = ''
    let formattedTextLabel = ''
    let postFormattedTextLabel = ''
    if (shortDescriptionA?.length) {
      const start_pos = shortDescriptionA.indexOf('*') + 1
      const end_pos = shortDescriptionA.indexOf('*', start_pos)
      if (start_pos !== -1 && end_pos > start_pos) {
        preFormattedTextLabel = shortDescriptionA.substring(0, start_pos - 1)
        formattedTextLabel = shortDescriptionA.substring(start_pos, end_pos)
        postFormattedTextLabel = shortDescriptionA.substring(
          end_pos + 1,
          shortDescriptionA.length
        )
      } else {
        promoDescription = shortDescriptionA
      }
    }
    return {
      preFormattedTextLabel,
      formattedTextLabel,
      postFormattedTextLabel,
      description: promoDescription,
    }
  }

  getShelfLifeImageSrc(shelfLife, selfLifeValue) {
    let shelfLifeImageSrc = null
    if (shelfLife && selfLifeValue) {
      shelfLifeImageSrc =
        selfLifeValue === '2'
          ? NineDayShelfLifeImg
          : selfLifeValue === '3'
          ? FourteenShelfLifeImg
          : null
    }
    return shelfLifeImageSrc
  }

  showDataOrHideSection(data, Section, item = '') {
    if (item === 'ratingStars') {
      return this.props.layoutTypeVoucher === 'horizontal' && !data ? (
        <span style={{ height: '0.5rem' }}></span>
      ) : (
        Section
      )
    }

    return this.props.layoutTypeVoucher === 'horizontal' && !data ? (
      <></>
    ) : (
      Section
    )
  }

  offerTextRefs = []
  render() {
    const {
      details,
      onSave,
      checkoutAddress,
      productPosition,
      preLoadFirstImage,
    } = this.props
    if (!details) {
      return null
    }
    let {
      name,
      images,
      adId,
      id,
      slug,
      metaData,
      offers,
      bulkOrderThreshold,
      tagIds,
      stockOverride,
      reviews,
      campaignLabelImage,
    } = details
    const { handlingTimeDisplayText, handlingTimeTrackingText, deltime } =
      getHandlingTimeDisplayText(
        details,
        this.props.checkoutAddress?.storeId,
        true
      )

    const storeBulkOrderThreshold =
      (stockOverride && stockOverride.storeBulkOrderThreshold) || 0

    const maxPurchasableStock =
      (stockOverride && stockOverride.maxPurchasableStock) || Infinity

    let storeSpecificData = []

    if (checkoutAddress && checkoutAddress.storeId) {
      storeSpecificData =
        details.storeSpecificData &&
        details.storeSpecificData.filter(
          item => item.storeId === checkoutAddress.storeId
        )

      if (!storeSpecificData || storeSpecificData.length === 0) {
        storeSpecificData = details.storeSpecificData
      }
    } else {
      storeSpecificData = details.storeSpecificData
    }

    if (storeSpecificData && storeSpecificData.length === 0) {
      storeSpecificData = [
        { ...details.storeSpecificData[0], stock: 0, unlimitedStock: false },
      ]
    }

    if (!storeSpecificData || storeSpecificData.length === 0) {
      return null
    }

    const price = +(
      storeSpecificData &&
      storeSpecificData.length > 0 &&
      storeSpecificData[0].mrp
    )
    let discount =
      storeSpecificData &&
      storeSpecificData.length > 0 &&
      storeSpecificData[0].discount

    const unlimitedStock =
      storeSpecificData &&
      storeSpecificData.length > 0 &&
      storeSpecificData[0].unlimitedStock
    const { clientX, clientY, ratingsConfig } = this.state

    const { sp, otherOffers } = offers ? filterSimplePromotion(offers) : {}

    offers = otherOffers

    const { isPwpApplicable, pwpDiscount } = getPwpOffer(offers, price)

    if (pwpDiscount) {
      discount = Number(pwpDiscount || 0)
    }

    const hasDiscount = discount > 0
    const isProductBoughtBefore =
      details?.pastPurchases && isBoughtBefore(details?.pastPurchases)

    const offersCount = this.state.isJwcTagApplicable ? 1 : 2

    const offersToShow =
      offers &&
      offers.slice(0, offersCount).map(offer => ({
        ...offer,
        description: offer.description.trim(),
        clamp: offersCount,
      }))

    //if both descriptions of promo texts are over text limit in 1 line
    //the second line will be reduced to be displayed in 1 line merely
    const textLimit = 30
    if (
      offers &&
      offers.length > 2 &&
      offersToShow[0]?.description.length > textLimit &&
      offersToShow[1]?.description.length > textLimit
    ) {
      offersToShow[1].clamp = 1
    }

    const itemIsOutOfStock = isOutOfStock({
      value: this.props.countOf(details),
      stock: storeSpecificData[0] && storeSpecificData[0].stock,
      unlimitedStock,
      bulkOrderThreshold,
      storeBulkOrderThreshold,
      checkoutAddress,
    })
    const pdpBrowerLink = this.state.notDefaultClick
      ? details?.adId
        ? `/product/${slug}?adId=${details.adId}`
        : `/product/${slug}`
      : `/product/${slug}`
    const shelfLifeExperiment = getExpConfig(SPLIT_FEATURES.SHELF_LIFE)
    const selfLifeValue = shelfLifeExperiment?.treatment
    const shelfLife = metaData?.shelfLife
    const shelfLifeImageSrc = this.getShelfLifeImageSrc(
      shelfLife,
      selfLifeValue
    )

    return (
      <RecommendedProductWrapper
        className="product-container"
        layoutTypeVoucher={this.props.layoutTypeVoucher}
        ref={this.setProductRef}
        data-refid={`${checkoutAddress?.clientId}-${details.clientItemId}`}
        data-impressiontype={this.props.impressionType}
      >
        <Link
          href={`/productDetails?slug=${slug}`}
          as={pdpBrowerLink}
          prefetch={false}
          passHref
          legacyBehavior
        >
          <StyledA
            onClick={this.handleTrackOnClick}
            inCart={this.props.includes({ id })}
          >
            <StyledContainer
              data-testid="product"
              layoutTypeVoucher={this.props.layoutTypeVoucher}
              parent
            >
              {this.getPromoContent(details.offers, sp, otherOffers)}
              <PlainDiv>
                {images && images.length > 0 ? (
                  <PaddingBox layoutTypeVoucher={this.props.layoutTypeVoucher}>
                    <ImageContainer
                      shelfLifeImageSrc
                      data-testid="recommended-product-image"
                    >
                      {shelfLifeImageSrc && (
                        <ShelfLifeImage
                          data-testid="shelfLife"
                          src={shelfLifeImageSrc}
                        />
                      )}
                      <ProgressiveImage
                        src={
                          typeof images[0] === 'string' &&
                          `${images[0]}?w=320&q=60`
                        }
                        shouldPreload={
                          preLoadFirstImage && productPosition === 1
                        }
                        shouldEagerLoad={productPosition <= 5}
                        originalSrc={`${images[0]}?w=320&q=60`}
                        alt={name || 'product-image'}
                        imageQuality={60}
                        title={name}
                        unoptimized={true}
                        objectFitContain={true}
                      />
                    </ImageContainer>
                  </PaddingBox>
                ) : (
                  <StyledNoImageIcon />
                )}
              </PlainDiv>
              <ItemNameAndAddToCart
                layoutTypeVoucher={this.props.layoutTypeVoucher}
                hasOfferLabel={
                  details.offers?.length > 0 || otherOffers?.length > 0
                }
              >
                <StyledInfo layoutTypeVoucher={this.props.layoutTypeVoucher}>
                  <PriceAndSaveButton>
                    <Div padding>
                      <Text size="large" weight="black">
                        <Price
                          size="large"
                          amount={price}
                          discount={discount}
                        />
                      </Text>
                      {hasDiscount && price > 0 && (
                        <Text size="small">
                          <Price outdated amount={price} />
                        </Text>
                      )}
                    </Div>
                    {price > 0 && (
                      <Div>
                        {onSave && (
                          <StyledSaveButton
                            data-testid="shortlistProduct"
                            onClick={event =>
                              this.handleShowList(
                                event,
                                this.props.countOf(details)
                              )
                            }
                          >
                            {this.props.wishlistConsumer.includes(details) ? (
                              <SvgFavouriteFilled
                                height="24"
                                width="24"
                                title="favourited"
                                color={defaultTheme.ColorBaseBrandPrimary500}
                              />
                            ) : (
                              <SvgFavourite
                                height="24"
                                width="24"
                                title="favourite"
                                color={defaultTheme.ColorBaseBrandPrimary500}
                              />
                            )}
                          </StyledSaveButton>
                        )}
                        {this.state.showList && (
                          <ShoppingListPopup
                            details={details}
                            position={{ clientX, clientY }}
                            isOpen={this.state.showList}
                            // eslint-disable-next-line react/jsx-handler-names
                            onHandleNotificationClosedByTimeOut={
                              this.props.onHandleNotificationClosedByTimeOut
                            }
                            onClose={() => this.setState({ showList: false })}
                          />
                        )}
                      </Div>
                    )}
                  </PriceAndSaveButton>
                  <StyledTitle
                    data-testid="product-name-and-metadata"
                    layoutTypeVoucher={this.props.layoutTypeVoucher}
                  >
                    <CampaignLabelContainer
                      layoutTypeVoucher={this.props.layoutTypeVoucher}
                    >
                      <Text
                        data-testid="highlighted-promo-label"
                        color={defaultTheme.ColorBaseBrandSecondary500}
                      >
                        {isPwpApplicable && <StyledLabel>PWP</StyledLabel>}
                        {isPwpApplicable && adId && (
                          <StyledDot data-testid="tag-separator" />
                        )}
                        {adId && <StyledLabel>Ad</StyledLabel>}
                      </Text>
                      <ProductCampaignLabel
                        tagIds={tagIds}
                        campaignLabelImage={campaignLabelImage}
                      />
                      <Text
                        size="medium"
                        weight="normal"
                        color={defaultTheme.ColorBaseMineShaft700}
                      >
                        {name}
                      </Text>
                    </CampaignLabelContainer>
                    <MetaDataContainer>
                      <ProductCardMetaData metaData={metaData} />
                    </MetaDataContainer>
                  </StyledTitle>
                </StyledInfo>
                {this.showDataOrHideSection(
                  reviews?.statistics?.average,
                  <RatingStars
                    ratingsconfig={ratingsConfig}
                    average={reviews?.statistics?.average}
                    ratingaverage={reviews?.statistics?.averageDisplay}
                    displayratings={reviews?.statistics?.totalDisplay}
                    ratingsrange={reviews?.statistics?.range}
                    total={reviews?.statistics?.total}
                    layoutTypeVoucher={this.props.layoutTypeVoucher}
                    isplp
                  />,
                  'ratingStars'
                )}
                {this.showDataOrHideSection(
                  deltime,
                  <MarketplaceItemLabelListContainer>
                    <MarketplaceItemLabelList
                      deltime={deltime}
                      itemIsOutOfStock={itemIsOutOfStock}
                    />
                  </MarketplaceItemLabelListContainer>
                )}
                {this.showDataOrHideSection(
                  isProductBoughtBefore,
                  <StyledPastPurchase
                    as="p"
                    size="small"
                    weight="bold"
                    color="#696969"
                  >
                    {isProductBoughtBefore && <span>Past Purchase</span>}
                  </StyledPastPurchase>
                )}
                <CartCounter
                  details={details}
                  price={price}
                  value={this.props.countOf(details)}
                  onValueChange={({ delta }) => {
                    this.props.update(
                      details,
                      delta,
                      () => {},
                      (_, totalDelta) => this.productCartAction(totalDelta)
                    )
                  }}
                  stock={storeSpecificData[0] && storeSpecificData[0].stock}
                  unlimitedStock={unlimitedStock}
                  bulkOrderThreshold={bulkOrderThreshold}
                  storeBulkOrderThreshold={storeBulkOrderThreshold}
                  maxPurchasableStock={maxPurchasableStock}
                  quantityAdjustedmessage={this.props.quantityAdjustedmessage}
                  outOfStockStyle={OutOfStockInProductCards}
                  handlingTimeDisplayText={handlingTimeDisplayText}
                  handlingTimeTrackingText={handlingTimeTrackingText}
                  isEnabledStoreHandlingTime={true}
                  checkoutAddress={checkoutAddress}
                  layoutTypeVoucher={this.props.layoutTypeVoucher}
                  isVerticalCard
                />
              </ItemNameAndAddToCart>
            </StyledContainer>
          </StyledA>
        </Link>
      </RecommendedProductWrapper>
    )
  }
}

RecommendedProduct.defaultProps = {
  defaultQuantity: 1,
}

export default withRouter(props => (
  <GlobalContext.Consumer>
    {({ remoteConfig }) => (
      <CheckoutAddressConsumer>
        {({ checkoutAddress }) => (
          <EventTrackingConsumer>
            {({ track, trackSplit }) => (
              <CartConsumer>
                {({
                  update,
                  includes,
                  countOf,
                  quantityAdjustedmessage,
                  items,
                }) => (
                  <WishlistConsumer>
                    {wishlistConsumer => (
                      <RecommendedProduct
                        {...props}
                        track={track}
                        trackSplit={trackSplit}
                        checkoutAddress={checkoutAddress}
                        update={update}
                        includes={includes}
                        countOf={countOf}
                        quantityAdjustedmessage={quantityAdjustedmessage}
                        remoteConfig={remoteConfig}
                        cartItems={items}
                        wishlistConsumer={wishlistConsumer}
                      />
                    )}
                  </WishlistConsumer>
                )}
              </CartConsumer>
            )}
          </EventTrackingConsumer>
        )}
      </CheckoutAddressConsumer>
    )}
  </GlobalContext.Consumer>
))
