import React from 'react'
import styled from 'styled-components'
import defaultTheme from '@ntuctech/devex-tangram/Theme/defaultTheme'
import { withMedia, SCREEN_SIZE } from './../../lib/Media'
import SvgAddToCart from './../icons/AddToCart'
import SvgDisabledAddCart from './../icons/DisabledAddCart'
import SvgRemoveFromCart from './../icons/RemoveFromCart'
import { trimZeroFromStart } from '../../lib/stringHelperFunctions'
import { FRESH_UNIT } from '../../lib/orderStatus'
import { CartConsumer } from '../CartProvider'
import { CheckoutAddressConsumer } from '../CheckoutAddressProvider'
import { PostOrderAmendConsumer } from '../PostOrdeAmendProvider/PostOrderAmendProvider'

export const CounterInput = styled.input`
  color: ${({ readOnly, showInRed }) =>
    readOnly
      ? showInRed
        ? '#dd0f42'
        : '#696969'
      : defaultTheme.ColorBaseBrandPrimary500};

  ${({ readOnly }) =>
    readOnly
      ? 'background-color: none; border: none;'
      : `
        background-color: ${defaultTheme.ColorBaseMineShaft40};
        border: 1px solid ${defaultTheme.ColorBaseMineShaft80};
      `}
  border-radius: 2px;

  margin: 0.25rem ${({ hasSteppers }) => (hasSteppers ? '0.5rem' : '0')};

  padding: 4px;

  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 700;

  text-align: center;

  ${({ layoutTypeVoucher }) =>
    layoutTypeVoucher === 'swimlane' && `margin: 0.25rem 0.25rem;`}

  &:focus {
    outline: none;
  }

  /* Input field widens as the font-size increases */
  width: 3.5rem;

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    /* stylelint-disable property-no-vendor-prefix */
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    appearance: none;
  }

  &[type='text'] {
    appearance: none;
    width: 5rem;
  }

  &::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }
  &::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  ${SCREEN_SIZE.Only.Mobile} {
    width: 3.5rem;
    height: 2rem;
    margin: 0 0.25rem;
  }
`

const Container = styled.div`
  ${SCREEN_SIZE.Only.Mobile} {
    ${({ isInProductDetail }) => isInProductDetail && 'width: 85vw;'}
  }
`

const CenterContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;

  > svg {
    cursor: pointer;
  }

  ${SCREEN_SIZE.Only.Mobile} {
    ${({ isInProductDetail }) => isInProductDetail && 'width: 14rem;'}
  }
`

const StyledDisabledAddCart = styled(SvgDisabledAddCart)`
  cursor: not-allowed !important;
`

const DisabledCartInProductDetail = styled(SvgAddToCart)`
  opacity: 0.2;
`

const StepButton = styled.div`
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: -0.25rem;
    bottom: -0.25rem;
    left: -0.25rem;
    right: -0.25rem;
  }
`

export function handleEnterCounter(e) {
  /*Fixed IE problem*/
  if (!e) {
    e = window.event
  }
  var keyCode = e.key
  if (keyCode === 'Enter') {
    e.preventDefault()
    e.target.blur()
    return
  }

  const specialCharacters = ['.', '+', '-', 'e']
  const isSpecialCharacter = specialCharacters.some(value => value === keyCode)
  if (isSpecialCharacter) {
    e.preventDefault()
  }
}

class Counter extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      counterValue: props.value,
      currentValue: props.value,
    }

    this.handleIncrement = this.handleIncrement.bind(this)
    this.handleDecrement = this.handleDecrement.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.handleOutOfQuantity = this.handleOutOfQuantity.bind(this)
    this.handlePreventDefault = this.handlePreventDefault.bind(this)
    this.handleDoubleClick = this.handleDoubleClick.bind(this)
    this.handleTouchStart = this.handleTouchStart.bind(this)
  }

  componentDidMount() {
    window.addEventListener('touchstart', this.handleTouchStart)
  }

  componentWillUnmount() {
    window.removeEventListener('touchstart', this.handleTouchStart)
  }

  isDescendant(parent, child) {
    var node = child
    while (node != null) {
      if (node.isEqualNode(parent)) {
        return true
      }
      node = node.parentNode
    }
    return false
  }

  handleTouchStart(event) {
    event.stopPropagation()

    const primaryTouch = event.targetTouches && event.targetTouches[0]

    if (!primaryTouch) {
      return
    }

    const targetElement = primaryTouch.target
    if (!this.isDescendant(this.counterContainerRef, targetElement)) {
      this.counterInputRef.blur()
    }
  }

  handleOutOfQuantity(e) {
    e && e.preventDefault()
    e && e.stopPropagation()
  }

  handleDoubleClick(e) {
    const selection = window.getSelection()
    const ele = e.target
    if (selection.rangeCount > 0) {
      selection.removeAllRanges()
    }

    const range = document.createRange()
    range.selectNode(ele)
    selection.addRange(range)
  }

  handlePreventDefault(event) {
    event.preventDefault()
    event.stopPropagation()
  }

  handleBlur() {
    let updateValue = this.state.currentValue

    if (this.checkBulk(this.state.currentValue)) {
      updateValue = this.getMaximumBeforeBulk(this.state.currentValue)
      this.setState({
        currentValue: updateValue,
      })
    }

    this.change(updateValue)
    this.setState({
      counterValue: updateValue,
    })
  }

  handleChange(event) {
    const currentValue = Number(event.target.value) || 0
    this.setState({
      currentValue: currentValue,
    })
  }

  change(nextValue) {
    if (typeof this.props.onChange !== 'function') {
      return
    }

    const { counterValue: prevValue } = this.state

    this.props.onChange({
      prevValue,
      nextValue,
      delta: nextValue - prevValue,
    })
  }

  handleDecrement(event) {
    event.preventDefault()
    event.stopPropagation()
    let newValue = Number(this.state.currentValue) - 1
    if (newValue < 0) {
      newValue = 0
    }
    this.setState({
      currentValue: newValue,
    })
    this.change(newValue)
    this.setState({
      counterValue: newValue,
    })
  }

  getMaximumBeforeBulk = newValue => {
    const { bulkOrderThreshold, storeBulkOrderThreshold } = this.props

    let max = newValue

    if (max >= (storeBulkOrderThreshold || Infinity)) {
      max = storeBulkOrderThreshold - 1
    }

    if (max >= (bulkOrderThreshold || Infinity)) {
      max = bulkOrderThreshold - 1
    }

    return max
  }

  checkBulk = newValue => {
    const {
      isOnPOAFlow,
      isInPfc,
      bulkOrderThreshold,
      storeBulkOrderThreshold,
    } = this.props

    // check bulk
    const isMoreThanBulk =
      newValue >= (storeBulkOrderThreshold || Infinity) ||
      newValue >= (bulkOrderThreshold || Infinity)
    const isStoreBulkStoreSwitch = !isInPfc && isMoreThanBulk
    if (isStoreBulkStoreSwitch && isOnPOAFlow) {
      this.props.showPoaPreventStoreSwitchPopup()
      return true
    }
    return false
  }

  handleIncrement(event) {
    event.preventDefault()
    event.stopPropagation()
    const newValue = Number(this.state.currentValue) + 1

    if (this.checkBulk(newValue)) {
      return
    }

    this.setState({
      currentValue: newValue,
    })
    this.change(newValue)
    this.setState({
      counterValue: newValue,
    })
  }

  handleMouseFocus(e) {
    /*Fixed IE problem*/
    // e.preventDefault()
    const value = e.target.value
    e.target.value = ''
    e.target.focus()
    e.target.value = value
  }

  componentDidUpdate(prevProps) {
    if (Number(this.props.value) !== Number(prevProps.value)) {
      this.setState({
        counterValue: Number(this.props.value),
        currentValue: Number(this.props.value),
      })
    }

    if (
      this.props.quantityAdjustedmessage !==
        prevProps.quantityAdjustedmessage &&
      this.state.counterValue !== Number(this.props.value)
    ) {
      // This is for updating counter when quantity is adjusted again after adjusted quatity is reached
      this.setState({
        counterValue: Number(this.props.value),
        currentValue: Number(this.props.value),
      })
    }
  }

  render() {
    const {
      onChange,
      hasSteppers,
      height,
      width,
      stock,
      unlimitedStock,
      bulkOrderThreshold,
      isInProductDetail,
      value,
      showInRed,
      isSoldByWeight,
      readOnlyCounter,
      storeBulkOrderThreshold,
      pfcItems,
      productId,
      isInPfc,
      maxPurchasableStock,
      layoutTypeVoucher,
    } = this.props
    const { currentValue } = this.state

    // since we are storing counterValue as number so the `00001` and `1` are same and stored as 1 so this do not update state and do not rerender the component
    // even if the value stored is 1 in state it will still keep the leading zeros in UI as the component which recives value from it is not rerendered
    // we can store counterValue as string or use a new reference to value everytime
    const trimmedValue = trimZeroFromStart(currentValue)

    // Without any possible way to manipulate the value, it is
    // automatically "read-only" by definition.
    const readOnly = typeof onChange !== 'function' && !hasSteppers
    const counterType = isSoldByWeight ? 'text' : 'number'
    const counterOnClick = isSoldByWeight ? '' : this.handlePreventDefault

    const isReachedStoreBulk =
      !isInPfc &&
      storeBulkOrderThreshold &&
      (storeBulkOrderThreshold <= trimmedValue ||
        storeBulkOrderThreshold <= value)

    //if a user keyin a value which is above global bulk, stock of that product will become unlimited
    const isReachedGlobalBulk =
      bulkOrderThreshold &&
      (bulkOrderThreshold <= trimmedValue || bulkOrderThreshold <= value)

    // We are unable to get PFC's stock from FFS store, so we need to keep a list of checked PFC's products locally.
    // Therefore, we can predict the stock availability of that product in PFC without any extra API calls to PFC store
    // The benefit can be we are able to disable `+` button, if a product is gonna hit bulk order but no stocks in PFC
    // Otherwise, we are waiting for back-end implementation to forsee PFC stock in FFS stores that will be a future plan
    // Firstly, before checking stock in PFC, this flag should be checked with store bulk reached
    let hasStockInPfc = isReachedStoreBulk
    if (isReachedStoreBulk && !isReachedGlobalBulk) {
      const localPfcProduct = pfcItems.find(item => item.id === productId)
      if (localPfcProduct) {
        hasStockInPfc =
          localPfcProduct.storeSpecificData &&
          localPfcProduct.storeSpecificData[0] &&
          localPfcProduct.storeSpecificData[0].stock - 1 >=
            parseInt(trimmedValue)
      } else {
        hasStockInPfc = true
      }
    }

    let availableStock = unlimitedStock ? Infinity : stock
    availableStock = Math.min(availableStock, maxPurchasableStock || Infinity)

    const isStockAvailable =
      isReachedGlobalBulk ||
      hasStockInPfc ||
      (!isReachedStoreBulk && availableStock && availableStock > trimmedValue)

    return (
      <Container
        ref={node => (this.counterContainerRef = node)}
        isInProductDetail={isInProductDetail}
      >
        <CenterContainer isInProductDetail={isInProductDetail}>
          {hasSteppers && (
            <StepButton onClick={this.handleDecrement}>
              <SvgRemoveFromCart
                data-testid="SvgRemoveFromCart"
                height={height}
                width={width}
              />
            </StepButton>
          )}
          <CounterInput
            ref={node => (this.counterInputRef = node)}
            aria-label="quantity"
            name="quantity"
            type={counterType}
            value={
              readOnly
                ? value
                : isSoldByWeight
                ? value + FRESH_UNIT
                : trimmedValue
            }
            onBlur={this.handleBlur}
            onChange={this.handleChange}
            onClick={counterOnClick}
            onDoubleClick={this.handleDoubleClick}
            readOnly={readOnly || readOnlyCounter}
            autoComplete="off"
            hasSteppers={hasSteppers}
            max={
              unlimitedStock ||
              stock > bulkOrderThreshold ||
              stock > storeBulkOrderThreshold
                ? undefined
                : stock
            }
            min={0}
            onMouseDown={this.handleMouseFocus}
            onKeyPress={handleEnterCounter}
            pattern="\d*"
            showInRed={showInRed}
            layoutTypeVoucher={layoutTypeVoucher}
          />

          {hasSteppers &&
            (isStockAvailable ? (
              <StepButton
                onClick={
                  isStockAvailable
                    ? this.handleIncrement
                    : this.handleOutOfQuantity
                }
              >
                <SvgAddToCart
                  data-testid="SvgAddToCart"
                  height={height}
                  width={width}
                />
              </StepButton>
            ) : isInProductDetail ? (
              <DisabledCartInProductDetail
                data-testid="DisabledCartInProductDetail"
                height={height}
                width={width}
                onClick={this.handleOutOfQuantity}
              />
            ) : (
              <StyledDisabledAddCart
                data-testid="StyledDisabledAddCart"
                height={height}
                width={width}
                onClick={this.handleOutOfQuantity}
              />
            ))}
        </CenterContainer>
      </Container>
    )
  }
}

Counter.defaultProps = {
  hasSteppers: true,
  value: 0,
  width: 40,
  height: 40,
}

const CounterWrapper = props => (
  <CheckoutAddressConsumer>
    {({ checkoutAddress }) => (
      <CartConsumer>
        {({ pfcItems = [] }) => (
          <PostOrderAmendConsumer>
            {({
              isAmendEnabled,
              orderToAmend,
              showPoaPreventStoreSwitchPopup,
            }) => (
              <Counter
                {...props}
                showPoaPreventStoreSwitchPopup={showPoaPreventStoreSwitchPopup}
                isOnPOAFlow={isAmendEnabled && orderToAmend}
                pfcItems={pfcItems}
                checkoutAddress={checkoutAddress}
              />
            )}
          </PostOrderAmendConsumer>
        )}
      </CartConsumer>
    )}
  </CheckoutAddressConsumer>
)

export default withMedia(CounterWrapper)
